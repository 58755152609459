'use client';

import { SpeedInsights } from '@vercel/speed-insights/react';
import { useRouter } from 'next/router';
export function Insights() {
  const router = useRouter();
  const {
    pathname
  } = router;
  return <SpeedInsights route={pathname} data-sentry-element="SpeedInsights" data-sentry-component="Insights" data-sentry-source-file="insights.tsx" />;
}